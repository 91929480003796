window.Yext = window.Yext || {};
import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';

import { Global } from 'js/common/global.js';

import { GoogleAnalytics } from 'js/components/GoogleAnalytics/index.js';

Global.init();
GoogleAnalytics.enableAutotracking('yext');
