import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { Instance as Analytics } from 'js/components/YextAnalytics/Analytics.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { AnalyticsDebugger } from 'ts/components/AnalyticsDebugger/AnalyticsDebugger.ts';
import { Debug } from 'js/components/Util/Debug.js'

import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      Yext.Analytics = Analytics;
      ImageObjectFit();
      svg4everybody();

      let currentYear = (new Date()).getFullYear();
      for (let dateSpan of document.querySelectorAll('.c-copy-date')) {
        dateSpan.innerHTML = `&copy;${currentYear}`;
      }

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      if (Debug.isEnabled()) {
        const analyticsDebugger = new AnalyticsDebugger(Yext.Analytics);
      }
    });
  }
}
